import { AppPages } from "./AppPages";
import { type RouteObject, redirect } from "react-router-dom";
import { adminRoutes } from "../admin/adminRoutes";
import { lazy } from "react";
import { contractLoader } from "../contract/contractLoader";
import { StaticLoadingScreen } from "../shared/LoadingScreen";
import { LoginCallbackPage } from "../auth/LoginCallbackPage";
import { loginCallbackLoader } from "../auth/LoginCallbackLoader";
import { NotFoundScreen } from "../shared/error/NotFoundScreen";
import { AppRoutes } from "./AppRoutes";

export const routes: RouteObject[] = [
  {
    path: AppRoutes[AppPages.LandingPage].relative,
    children: [
      adminRoutes,
      {
        Component: lazy(() =>
          import("../renderer/PageShell").then((m) => ({
            default: m.PageShell,
          })),
        ),
        children: [
          {
            path: "/t/:token",
            children: [
              {
                path: ":path",
                Component: lazy(() =>
                  import("./ShortContractUrlRoute").then((m) => ({
                    default: m.Component,
                  })),
                ),
              },
              {
                index: true,
                Component: lazy(() =>
                  import("./ShortContractUrlRoute").then((m) => ({
                    default: m.Component,
                  })),
                ),
              },
            ],
          },
          {
            path: "/i/:token",
            children: [
              {
                path: ":path",
                Component: lazy(() =>
                  import("./ShortInvitationRoute").then((m) => ({
                    default: m.Component,
                  })),
                ),
              },
              {
                index: true,
                Component: lazy(() =>
                  import("./ShortInvitationRoute").then((m) => ({
                    default: m.Component,
                  })),
                ),
              },
            ],
          },
          {
            path: AppRoutes[AppPages.Create].relative,
            Component: lazy(() =>
              import("../contract/create-contract/CreateContractPage").then(
                (m) => ({ default: m.CreateContractPage }),
              ),
            ),
          },
          {
            path: AppRoutes[AppPages.AboutLandingPage].relative,
            children: [
              {
                path: AppRoutes[AppPages.AboutDigitalSigning].relative,
                Component: lazy(() =>
                  import("../about/pages/DigitalSigningPage").then((m) => ({
                    default: m.DigitalSigningPage,
                  })),
                ),
              },
              {
                path: AppRoutes[AppPages.AboutFaq].relative,
                Component: lazy(() =>
                  import("../about/faq/FaqPage").then((m) => ({
                    default: m.FaqPage,
                  })),
                ),
              },
              {
                path: AppRoutes[AppPages.AboutHowItWorks].relative,
                Component: lazy(() =>
                  import("../about/pages/HowItWorksPage").then((m) => ({
                    default: m.HowItWorksPage,
                  })),
                ),
              },
              {
                path: AppRoutes[AppPages.AboutOwnershipTransfer].relative,
                Component: lazy(() =>
                  import("../about/pages/AboutOwnershipTransferPage").then(
                    (m) => ({
                      default: m.AboutOwnershipTransferPage,
                    }),
                  ),
                ),
              },
              {
                path: AppRoutes[AppPages.AboutVipps].relative,
                Component: lazy(() =>
                  import("../about/pages/AboutVippsPage").then((m) => ({
                    default: m.AboutVippsPage,
                  })),
                ),
              },
              {
                path: AppRoutes[AppPages.AboutInstructions].relative,
                Component: lazy(() =>
                  import("../about/pages/InstructionsPage").then((m) => ({
                    default: m.InstructionsPage,
                  })),
                ),
              },
              {
                path: AppRoutes[AppPages.AboutPrivacy].relative,
                Component: lazy(() =>
                  import("../about/pages/PrivacyPage").then((m) => ({
                    default: m.PrivacyPage,
                  })),
                ),
              },
              {
                path: AppRoutes[AppPages.AboutStaticContracts].relative,
                Component: lazy(() =>
                  import("../about/pages/StaticContractsPage").then((m) => ({
                    default: m.StaticContractsPage,
                  })),
                ),
              },
              {
                path: AppRoutes[AppPages.AboutTerms].relative,
                Component: lazy(() =>
                  import("../about/pages/TermsPage").then((m) => ({
                    default: m.TermsPage,
                  })),
                ),
              },
              {
                path: AppRoutes[AppPages.AboutUpgrade].relative,
                Component: lazy(() =>
                  import("../about/pages/AboutUpgradePage").then((m) => ({
                    default: m.AboutUpgradePage,
                  })),
                ),
              },
              {
                index: true,
                Component: lazy(() =>
                  import("../about/pages/AboutLandingPage").then((m) => ({
                    default: m.AboutLandingPage,
                  })),
                ),
              },
            ],
          },
          {
            path: AppRoutes[AppPages.LandingPageMc].relative,
            Component: lazy(() =>
              import("../landing/pages/McLandingPage").then((m) => ({
                default: m.McLandingPage,
              })),
            ),
          },
          {
            path: AppRoutes[AppPages.LandingPageCaravan].relative,
            Component: lazy(() =>
              import("../landing/pages/CaravanLandingPage").then((m) => ({
                default: m.CaravanLandingPage,
              })),
            ),
          },
          {
            path: AppRoutes[AppPages.BecomeMember].relative,
            children: [
              {
                path: "callback/:stateId",
                Component: lazy(() =>
                  import("../membership/MembershipPaymentPage").then((m) => ({
                    default: m.MembershipPaymentPage,
                  })),
                ),
              },
              {
                path: AppRoutes[AppPages.BecomeMemberWelcome].relative,
                Component: lazy(() =>
                  import("../membership/BecomeMemberWelcomePage").then((m) => ({
                    default: m.BecomeMemberWelcomePage,
                  })),
                ),
              },
              {
                path: AppRoutes[AppPages.BecomeMemberForm].relative,
                Component: lazy(() =>
                  import("../membership/BecomeMemberFormPage").then((m) => ({
                    default: m.BecomeMemberFormPage,
                  })),
                ),
              },
              {
                index: true,
                Component: lazy(() =>
                  import("../membership/BecomeMemberPage").then((m) => ({
                    default: m.BecomeMemberPage,
                  })),
                ),
              },
            ],
          },
          {
            path: "signering/:token",
            children: [
              {
                path: "callback",
                Component: lazy(() =>
                  import(
                    "../contract/signing-task/SigningTaskCallbackPage"
                  ).then((m) => ({ default: m.SigningTaskCallbackPage })),
                ),
              },
              {
                index: true,
                Component: lazy(() =>
                  import("../contract/signing-task/SigningTaskPage").then(
                    (m) => ({ default: m.SigningTaskPage }),
                  ),
                ),
              },
            ],
          },
          {
            path: "sign/oidc-callback",
            Component: lazy(() =>
              import("../contract/signing/OidcSignCallbackPage").then((m) => ({
                default: m.OidcSignCallbackPage,
              })),
            ),
          },
          {
            path: "login/callback",
            HydrateFallback: StaticLoadingScreen,
            loader: loginCallbackLoader,
            Component: LoginCallbackPage,
          },
          {
            path: "logout/callback",
            Component: lazy(() =>
              import("../auth/LogoutCallbackPage").then((m) => ({
                default: m.LogoutCallbackPage,
              })),
            ),
          },
          {
            path: "payment",
            Component: lazy(() =>
              import("../payments/StaticPaymentLandingPage").then((m) => ({
                default: m.StaticPaymentLandingPage,
              })),
            ),
          },
          /* TODO: AppPages */
          {
            path: "betaling/:paymentId/:signature",
            Component: lazy(() =>
              import("../payments/PaymentPage").then((m) => ({
                default: m.PaymentPage,
              })),
            ),
          },
          {
            path: "arkiv",
            Component: lazy(() =>
              import("../shared/error/ArchivedContractScreen").then((m) => ({
                default: m.ArchivedContractScreen,
              })),
            ),
          },
          {
            path: "karantene",
            Component: lazy(() =>
              import("../shared/error/QuarantinedContractScreen").then((m) => ({
                default: m.QuarantinedContractScreen,
              })),
            ),
          },
          {
            path: AppRoutes[AppPages.Invitation].relative,
            Component: lazy(() =>
              import("../contract/invitation/InvitationLandingPage").then(
                (m) => ({
                  default: m.InvitationLandingPage,
                }),
              ),
            ),
          },
          {
            path: AppRoutes[AppPages.Contract].relative,
            Component: lazy(() =>
              import("../contract/routing/ContractRoutes").then((m) => ({
                default: m.ContractRoutes,
              })),
            ),
            loader: contractLoader,
            HydrateFallback: StaticLoadingScreen,
            children: [
              {
                path: AppRoutes[AppPages.ContractLoginCallback].relative,
                Component: lazy(() =>
                  import(
                    "../contract/contract-type/ContractLoginCallbackPage"
                  ).then((m) => ({ default: m.ContractLoginCallbackPage })),
                ),
              },
              {
                path: "lagre",
                Component: lazy(() =>
                  import("../contract/save-contract/SaveContractPage").then(
                    (m) => ({ default: m.SaveContractPage }),
                  ),
                ),
              },
              {
                path: AppRoutes[AppPages.ContractForm].relative,
                Component: lazy(() =>
                  import("../contract/form/routing/ContractFormRoutes").then(
                    (m) => ({ default: m.ContractFormRoutes }),
                  ),
                ),
                children: [
                  {
                    path: AppRoutes[AppPages.ContractFormAboutVehicle].relative,
                    Component: lazy(() =>
                      import("../contract/form/fields/AboutVehiclePage").then(
                        (m) => ({ default: m.AboutVehiclePage }),
                      ),
                    ),
                  },
                  {
                    path: AppRoutes[AppPages.ContractFormContactInfo].relative,
                    Component: lazy(() =>
                      import(
                        "../contract/form/contact-info/ContactInfoPage"
                      ).then((m) => ({ default: m.ContactInfoPage })),
                    ),
                  },
                  {
                    path: AppRoutes[AppPages.ContractFormAboutPayment].relative,
                    Component: lazy(() =>
                      import("../contract/form/fields/AboutPaymentPage").then(
                        (m) => ({ default: m.AboutPaymentPage }),
                      ),
                    ),
                  },
                  {
                    path: AppRoutes[AppPages.ContractFormInsurance].relative,
                    Component: lazy(() =>
                      import("../contract/form/insurance/InsurancePage").then(
                        (m) => ({ default: m.InsurancePage }),
                      ),
                    ),
                  },
                  {
                    path: AppRoutes[AppPages.ContractFormAttachments].relative,
                    Component: lazy(() =>
                      import(
                        "../contract/form/attachments/AttachmentsPage"
                      ).then((m) => ({ default: m.AttachmentsPage })),
                    ),
                  },
                  {
                    path: AppRoutes[AppPages.ContractFormType].relative,
                    Component: lazy(() =>
                      import(
                        "../contract/form/select-type/ContractTypePage"
                      ).then((m) => ({ default: m.ContractTypePage })),
                    ),
                  },
                  {
                    index: true,
                    loader: (x) => {
                      const nextPath =
                        AppRoutes[AppPages.ContractFormAboutVehicle].relative;
                      const nextSearch = new URL(
                        x.request.url,
                      ).searchParams.toString();
                      const nextLocation = `${nextPath}?${nextSearch}`;
                      return redirect(nextLocation);
                    },
                  },
                ],
              },
              {
                path: AppRoutes[AppPages.ContractMembershipUpgrade].relative,
                Component: lazy(() =>
                  import(
                    "../contract/contract-type/membership-upgrade/MembershipUpgradePage"
                  ).then((m) => ({ default: m.MembershipUpgradePage })),
                ),
              },
              {
                path: AppRoutes[AppPages.ContractPreview].relative,
                Component: lazy(() =>
                  import("../contract/preview/PreviewPage").then((m) => ({
                    default: m.PreviewPage,
                  })),
                ),
              },
              {
                path: "bankid/oppgrader-kontrakt",
                Component: lazy(() =>
                  import(
                    "../contract/contract-type/SigningMethodUpgradeContractPage"
                  ).then((m) => ({
                    default: m.SigningMethodUpgradeContractPage,
                  })),
                ),
              },
              {
                path: "signer/:hash",
                children: [
                  {
                    path: AppRoutes[AppPages.ContractSignCallback].relative,
                    Component: lazy(() =>
                      import("../contract/signing/SigningCallbackPage").then(
                        (m) => ({ default: m.SigningCallbackPage }),
                      ),
                    ),
                  },
                  {
                    index: true,
                    Component: lazy(() =>
                      import("../contract/signing/SigningPage").then((m) => ({
                        default: m.SigningPage,
                      })),
                    ),
                  },
                ],
              },
              {
                path: AppRoutes[AppPages.ContractTrialMembership].relative,
                Component: lazy(() =>
                  import(
                    "../contract/contract-type/trial-membership/TrialMembershipRoutesWrapper"
                  ).then((m) => ({
                    default: m.TrialMembershipRoutesWrapper,
                  })),
                ),
                children: [
                  {
                    path: AppRoutes[
                      AppPages.ContractTrialMembershipOrchestration
                    ].relative,
                    Component: lazy(() =>
                      import(
                        "../contract/contract-type/trial-membership/ActivateTrialMembershipOrchestrationPage"
                      ).then((m) => ({
                        default: m.ActivateTrialMembershipOrchestrationPage,
                      })),
                    ),
                  },
                  {
                    index: true,
                    Component: lazy(() =>
                      import(
                        "../contract/contract-type/trial-membership/ActivateTrialMembershipPage"
                      ).then((m) => ({
                        default: m.ActivateTrialMembershipPage,
                      })),
                    ),
                  },
                ],
              },
              {
                path: AppRoutes[AppPages.ContractUpgradeProductRoot].relative,
                Component: lazy(() =>
                  import(
                    "../contract/contract-type/contract-upgrade/ContractUpgradePaymentRoot"
                  ).then((m) => ({
                    default: m.ContractUpgradePaymentRoot,
                  })),
                ),
                children: [
                  {
                    index: true,
                    Component: lazy(() =>
                      import(
                        "../contract/contract-type/contract-upgrade/CreatePaymentPage"
                      ).then((m) => ({ default: m.CreatePaymentPage })),
                    ),
                  },
                ],
              },
              {
                path: AppRoutes[AppPages.ContractStatus].relative,
                Component: lazy(() =>
                  import("../contract/status/StatusPage").then((m) => ({
                    default: m.StatusPage,
                  })),
                ),
              },
              {
                path: AppRoutes[AppPages.ContractConfirmationLanding].relative,
                Component: lazy(() =>
                  import(
                    "../contract/confirmation/ConfirmationLandingPageWrapper"
                  ).then((m) => ({
                    default: m.ConfirmationLandingPageWrapper,
                  })),
                ),
                children: [
                  {
                    index: true,
                    Component: lazy(() =>
                      import(
                        "../contract/confirmation/ConfirmationLandingPageRedirecter"
                      ).then((m) => ({
                        default: m.ConfirmationLandingPageRedirecter,
                      })),
                    ),
                  },
                  {
                    path: AppRoutes[AppPages.ContractConfirmationWaitingForPdf]
                      .relative,
                    Component: lazy(() =>
                      import("../contract/confirmation/WaitingForPdfPage").then(
                        (m) => ({ default: m.WaitingForPdfPage }),
                      ),
                    ),
                  },
                  {
                    path: AppRoutes[AppPages.ContractConfirmationSettlement]
                      .relative,
                    Component: lazy(() =>
                      import(
                        "../contract/confirmation/settlement/SettlementPage"
                      ).then((m) => ({ default: m.SettlementPage })),
                    ),
                  },
                  {
                    path: AppRoutes[AppPages.ContractConfirmationSummary]
                      .relative,
                    Component: lazy(() =>
                      import(
                        "../contract/confirmation/summary/SummaryPage"
                      ).then((m) => ({ default: m.SummaryPage })),
                    ),
                  },
                  {
                    path: AppRoutes[AppPages.ContractConfirmationInsurance]
                      .relative,
                    Component: lazy(() =>
                      import(
                        "../contract/confirmation/PostConfirmationCarInsurancePage"
                      ).then((m) => ({
                        default: m.PostConfirmationCarInsurancePage,
                      })),
                    ),
                  },
                  {
                    path: AppRoutes[
                      AppPages.ContractConfirmationTrialMembership
                    ].relative,
                    children: [
                      {
                        path: AppRoutes[
                          AppPages
                            .ContractConfirmationTrialMembershipOrchestration
                        ].relative,
                        Component: lazy(() =>
                          import(
                            "../contract/confirmation/trial-membership/PostConfirmationTrialMembershipOrchestrationPage"
                          ).then((m) => ({
                            default:
                              m.PostConfirmationTrialMembershipOrchestrationPage,
                          })),
                        ),
                      },
                      {
                        index: true,
                        Component: lazy(() =>
                          import(
                            "../contract/confirmation/trial-membership/PostConfirmationActivateTrialMembershipPage"
                          ).then((m) => ({
                            default:
                              m.PostConfirmationActivateTrialMembershipPage,
                          })),
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: AppRoutes[AppPages.ContractInvitation].relative,
                Component: lazy(() =>
                  import("../contract/invitation/RedirectToInvitation").then(
                    (m) => ({ default: m.RedirectToInvitation }),
                  ),
                ),
              },
              {
                index: true,
                Component: lazy(() =>
                  import(
                    "../contract/contract-landing/ContractLandingPage"
                  ).then((m) => ({ default: m.ContractLandingPage })),
                ),
              },
            ],
          },
          {
            path: "*",
            Component: NotFoundScreen,
          },
          {
            index: true,
            Component: lazy(() =>
              import("../landing/pages/DefaultLandingPage").then((m) => ({
                default: m.DefaultLandingPage,
              })),
            ),
          },
        ],
      },
    ],
  },
];
