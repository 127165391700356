import { ConnectionStatus } from "./ConnectionStatus";

export type OnMessage = (event: MessageEvent) => void;

export class WebSocketManager {
  private _connectionAttempts = 0;

  socket: WebSocket | null = null;
  status = ConnectionStatus.None;

  private _updateStatus(status: ConnectionStatus) {
    this.status = status;
    this._emit();
  }

  private _emit() {
    for (const listener of this._statusListeners) listener();
  }

  connect(connectingStatus?: ConnectionStatus) {
    const {
      location: { protocol, hostname, port },
    } = document;

    const wsPort = port || (protocol === "https:" ? "443" : "80");

    const uri =
      hostname === "localhost"
        ? `${protocol.replace("http", "ws")}//${hostname}:${wsPort}/ws`
        : `${protocol.replace("http", "ws")}//ws.${hostname}:${wsPort}`;

    console.log(`Connecting to WebSocket on ${uri}`);

    try {
      this._updateStatus(connectingStatus ?? ConnectionStatus.Connecting);

      const socket = new WebSocket(uri);
      this.socket = socket;

      socket.onopen = () => {
        this._connectionAttempts = 0;
        this._updateStatus(ConnectionStatus.Connected);
      };

      socket.onclose = () => {
        this._updateStatus(ConnectionStatus.Disconnected);
        this._attemptAutoReconnect();
      };

      socket.onerror = (event: Event) => {
        console.log("onerror", event);

        this._updateStatus(ConnectionStatus.Disconnected);
      };

      socket.onmessage = (event) => {
        for (const listener of this._messageListeners) listener(event);
      };
    } catch (error) {
      this._updateStatus(ConnectionStatus.Failed);
      console.error("Could not create WebSocket", error);
      return null;
    }
  }

  async _attemptAutoReconnect() {
    if (this._connectionAttempts < 3) {
      const BASE = 1000;
      const waitMs = Math.floor(
        Math.random() * BASE * 2 ** this._connectionAttempts,
      );

      console.log(`Waiting ${waitMs} ms before reconnecting...`);

      await new Promise((resolve) => setTimeout(resolve, waitMs));

      console.log(
        `Attempting reconnect after ${this._connectionAttempts} attempts...`,
      );

      this._connectionAttempts += 1;
      this.connect(ConnectionStatus.Reconnecting);
    } else {
      this._updateStatus(ConnectionStatus.Disconnected);
    }
  }

  private _statusListeners: (() => void)[] = [];

  subscribeToStatus(listener: () => void) {
    this._statusListeners.push(listener);

    return () => {
      const index = this._statusListeners.indexOf(listener);
      if (index !== -1) {
        this._statusListeners.splice(index, 1);
      }
    };
  }

  private _messageListeners: OnMessage[] = [];

  onMessage(listener: OnMessage) {
    this._messageListeners.push(listener);

    return () => {
      const index = this._messageListeners.indexOf(listener);
      if (index !== -1) {
        this._messageListeners.splice(index, 1);
      }
    };
  }
}

export const webSocketManager = new WebSocketManager();
