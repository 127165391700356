import type { LoaderFunction } from "react-router-dom";
import type { ContractToken } from "./model/ContractViewModel";
import { fetchContract } from "./FetchContract";
import type { FetchContractResult } from "../api/contractFetcher";
import { captureException } from "@sentry/browser";

export const contractLoader: LoaderFunction = async ({
  request,
}): Promise<null | FetchContractResult> => {
  if (typeof window === "undefined") return null;
  const token = new URL(request.url).searchParams.get("token");
  if (!token) return null;

  try {
    return await fetchContract(token as ContractToken);
  } catch (error) {
    console.error(error);
    captureException(error);
    return null;
  }
};

contractLoader.hydrate = false;
