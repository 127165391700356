import { CONTRACT_TYPE_RATH_PARAM } from "../contract/contract-type/contract-upgrade/locationParams";
import { AppPages } from "./AppPages";

export interface AppPathDescription<TParams extends string> {
  relative: string;
  base: string;
}

type PathParam<Path extends string> = Path extends `${infer L}/${infer R}`
  ? PathParam<L> | PathParam<R>
  : Path extends `:${infer Param}`
    ? Param
    : never;

export function buildAppRoute<TRelative extends string, TBase extends string>(
  relative: TRelative,
  base: TBase = "" as TBase,
): AppPathDescription<PathParam<TRelative> | PathParam<TBase>> {
  return {
    relative,
    base,
  };
}

export const AppRoutes = {
  [AppPages.LandingPage]: buildAppRoute("/"),
  [AppPages.LandingPageMc]: buildAppRoute("mc", "/"),
  [AppPages.LandingPageCaravan]: buildAppRoute("campingvogn-og-bobil", "/"),
  [AppPages.AboutLandingPage]: buildAppRoute("om", "/"),
  [AppPages.AboutDigitalSigning]: buildAppRoute("digital-signering", "/om/"),
  [AppPages.AboutFaq]: buildAppRoute("sporsmal-og-svar", "/om/"),
  [AppPages.AboutHowItWorks]: buildAppRoute("slik-fungerer-det", "/om/"),
  [AppPages.AboutOwnershipTransfer]: buildAppRoute("eierskifte", "/om/"),
  [AppPages.AboutVipps]: buildAppRoute("vipps", "/om/"),
  [AppPages.AboutInstructions]: buildAppRoute("utfylling-av-kontrakt", "/om/"),
  [AppPages.AboutPrivacy]: buildAppRoute("cookies-og-personvern", "/om/"),
  [AppPages.AboutStaticContracts]: buildAppRoute("last-ned-kontrakt", "/om/"),
  [AppPages.AboutTerms]: buildAppRoute("vilkar", "/om/"),
  [AppPages.AboutUpgrade]: buildAppRoute("oppgradering", "/om/"),
  [AppPages.Create]: buildAppRoute("opprett/:contractType", "/"),
  [AppPages.BecomeMember]: buildAppRoute("bli-medlem", "/"),
  [AppPages.BecomeMemberWelcome]: buildAppRoute("velkommen", "/bli-medlem/"),
  [AppPages.BecomeMemberForm]: buildAppRoute(":productNumber", "/bli-medlem/"),
  [AppPages.Invitation]: buildAppRoute("invitasjon", "/"),
  [AppPages.Contract]: buildAppRoute("kontrakt", "/"),
  [AppPages.ContractInvitation]: buildAppRoute("invitasjon", "/kontrakt/"),
  [AppPages.ContractForm]: buildAppRoute("utfylling", "/kontrakt/"),
  [AppPages.ContractFormAboutVehicle]: buildAppRoute(
    "om-kjoretoyet",
    "/kontrakt/utfylling/",
  ),
  [AppPages.ContractFormContactInfo]: buildAppRoute(
    "kontaktinfo",
    "/kontrakt/utfylling/",
  ),
  [AppPages.ContractFormAboutPayment]: buildAppRoute(
    "om-betaling",
    "/kontrakt/utfylling/",
  ),
  [AppPages.ContractFormAttachments]: buildAppRoute(
    "vedlegg",
    "/kontrakt/utfylling/",
  ),
  [AppPages.ContractFormInsurance]: buildAppRoute(
    "forsikring",
    "/kontrakt/utfylling/",
  ),
  [AppPages.ContractFormType]: buildAppRoute(
    "velg-type",
    "/kontrakt/utfylling/",
  ),
  [AppPages.ContractMembershipUpgrade]: buildAppRoute(
    "oppgrader/medlem",
    "/kontrakt/",
  ),
  [AppPages.ContractLoginCallback]: buildAppRoute("innlogging", "/kontrakt/"),
  [AppPages.ContractPreview]: buildAppRoute("forhandsvisning", "/kontrakt/"),
  [AppPages.ContractSaveContract]: buildAppRoute("lagre", "/kontrakt/"),
  [AppPages.ContractSignCallback]: buildAppRoute(
    "callback",
    "/kontrakt/signer/:hash/",
  ),
  [AppPages.ContractSigningMethodUpgradeContract]: buildAppRoute(
    "bankid/oppgrader-kontrakt",
    "/kontrakt/",
  ),
  [AppPages.ContractSign]: buildAppRoute(":hash", "/kontrakt/signer/"),
  [AppPages.ContractStatus]: buildAppRoute("status", "/kontrakt/"),
  [AppPages.ContractUpgradeProductRoot]: buildAppRoute(
    `oppgradering/:${CONTRACT_TYPE_RATH_PARAM}`,
    "/kontrakt/",
  ),
  [AppPages.ContractTrialMembership]: buildAppRoute(
    "provemedlemskap",
    "/kontrakt/",
  ),
  [AppPages.ContractTrialMembershipOrchestration]: buildAppRoute(
    ":orchestrationId",
    "/kontrakt/provemedlemskap/",
  ),
  [AppPages.ContractConfirmationLanding]: buildAppRoute(
    "bekreftelse",
    "/kontrakt/",
  ),
  [AppPages.ContractConfirmationWaitingForPdf]: buildAppRoute(
    "pdf",
    "/kontrakt/bekreftelse/",
  ),
  [AppPages.ContractConfirmationSettlement]: buildAppRoute(
    "oppgjor",
    "/kontrakt/bekreftelse/",
  ),
  [AppPages.ContractConfirmationSummary]: buildAppRoute(
    "oppsummering",
    "/kontrakt/bekreftelse/",
  ),
  [AppPages.ContractConfirmationInsurance]: buildAppRoute(
    "forsikring",
    "/kontrakt/bekreftelse/",
  ),
  [AppPages.ContractConfirmationTrialMembership]: buildAppRoute(
    "provemedlemskap",
    "/kontrakt/bekreftelse/",
  ),
  [AppPages.ContractConfirmationTrialMembershipOrchestration]: buildAppRoute(
    ":orchestrationId",
    "/kontrakt/bekreftelse/provemedlemskap/",
  ),
};

export type PathParamsMap<TRoute> = TRoute extends AppPathDescription<
  infer TParams
>
  ? [TParams] extends [never]
    ? Record<never, void>
    : { params: { [key in TParams]: string } }
  : unknown;

export type GetPath<TPage extends AppPages> = {
  page: TPage;
} & PathParamsMap<(typeof AppRoutes)[TPage]>;

export function getAbsolutePath<TPage extends AppPages>(
  getPath: GetPath<TPage>,
): string {
  const route = AppRoutes[getPath.page];
  let path = `${route.base}${route.relative}`;

  if (!("params" in getPath)) {
    return path;
  }

  for (const [param, value] of Object.entries(getPath.params)) {
    path = path.replace(`:${param}`, value as string);
  }
  return path;
}
